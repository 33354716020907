import clsx from 'clsx';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Image from 'next/image';
import { ProSidebarProvider, Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import Socials from '../common/Socials';
import ContactInfo from '../common/ContactInfo';

const MobileMenu = ({ additionalItems }) => {
  const router = useRouter();

  return (
    <>
      <div className='pro-header d-flex align-items-center justify-between border-bottom-light'>
        <Link className='d-flex align-items-center' href='/'>
          <Image
            src='/img/vclogo_text_black.png'
            width={270}
            height={100}
            alt='victoria court logo'
            style={{ width: 270 }}
          />
        </Link>

        <div
          className='fix-icon'
          data-bs-dismiss='offcanvas'
          aria-label='Close'
        >
          <i className='icon icon-close'></i>
        </div>
      </div>

      <ProSidebarProvider>
        <Sidebar width='400' backgroundColor='#fff'>
          <Menu>
            {additionalItems.includes('rooms') && (
              <MenuItem
                component={
                  <Link
                    href='/rooms'
                    className={clsx(
                      router.pathname === '/rooms' && 'menu-active-link'
                    )}
                  />
                }
              >
                Rooms
              </MenuItem>
            )}
            <MenuItem
              component={
                <Link
                  href='/about'
                  className={clsx(
                    router.pathname === '/about' && 'menu-active-link'
                  )}
                />
              }
            >
              About
            </MenuItem>

            <MenuItem
              component={
                <Link
                  href='/help'
                  className={clsx(
                    router.pathname === '/help' && 'menu-active-link'
                  )}
                />
              }
            >
              Help
            </MenuItem>

            <div className='mobile-footer px-20 py-5 mt-15 mb-15 border-top-light'></div>

            <MenuItem component={<Link href='https://www.victoriabites.com' />}>
              Victoria Bites
            </MenuItem>

            <MenuItem
              component={
                <Link href='https://webstore.paynamics.net/victoriacourtvc' />
              }
            >
              Victoria Style
            </MenuItem>
          </Menu>
        </Sidebar>
      </ProSidebarProvider>

      {additionalItems.includes('book_now') && (
        <div className='btn-group'>
          <button
            type='button'
            className='button btn dropdown-toggle h-50 px-24 text-white btn-mobile'
            data-bs-toggle='dropdown'
            aria-expanded='false'
          >
            Book Now
          </button>
          <ul className='dropdown-menu dropdown-menu-end'>
            <li>
              <Link
                className='dropdown-item text-white'
                href={{ pathname: '/rooms', query: { isparty: false } }}
                as='/rooms'
              >
                Couple Room
              </Link>
            </li>
            <li>
              <hr className='dropdown-divider' />
            </li>
            <li>
              <Link
                className='dropdown-item text-white'
                href={{ pathname: '/rooms', query: { isparty: true } }}
                as='/rooms'
              >
                Party Room
              </Link>
            </li>
          </ul>
        </div>
      )}

      <div className='mobile-footer mt-20 px-20 py-5 border-top-light'></div>

      <div className='pro-footer y-gap-20'>
        <ContactInfo />
        <div>
          <h5 className='text-16 fw-500 mb-10'>Follow us on social media</h5>
          <div className='d-flex x-gap-20 items-center'>
            <Socials />
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
