import Link from 'next/link';
import ContactInfo from '../common/ContactInfo';
import Socials from '../common/Socials';

const index = () => {
  return (
    <footer className='footer -type-1 no-print'>
      <div className='container'>
        <div className='pt-40 pb-60'>
          <div className='row y-gap-40 xl:justify-start'>
            <div className='col-lg-3 col-sm-12'>
              <h5 className='text-16 fw-500'>Contact</h5>
              <div className='mt-20'>
                <ContactInfo
                  classNames={{
                    container: 'mt-10',
                    title: 'text-16',
                    subtitle: 'text-14',
                    body: 'text-18 text-blue-1 fw-500 mt-5',
                  }}
                />
              </div>
            </div>

            {content.map((item, i) => (
              <div className='col-xl-2 col-lg-4 col-sm-6' key={i}>
                <h5 className='text-16 fw-500 mb-30'>{item.title}</h5>
                <div className='d-flex y-gap-10 flex-column'>
                  {item.menulist.map((menu, i) => (
                    <Link href={menu.path} key={i}>
                      {menu.name}
                    </Link>
                  ))}
                </div>
              </div>
            ))}

            <div className='col-xl-2 col-lg-4 col-sm-6'>
              <h5 className='text-16 fw-500 mb-30'>
                Follow us on social media
              </h5>
              <div className='d-flex x-gap-10 items-center'>
                <Socials />
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

const content = [
  {
    title: 'Related Links',
    menulist: [
      { name: 'Victoria Bites', path: 'https://www.victoriabites.com/' },
      { name: 'Victoria Style', path: 'https://webstore.paynamics.net/victoriacourtvc' },
    ],
  },
  {
    title: 'Legal Terms and Conditions and Guidelines',
    menulist: [
      {
        name: 'Terms and Conditions',
        path: '/policies#terms-and-conditions',
      },
      { name: 'Privacy Policy', path: '/policies#privacy-policy' },
      { name: 'Booking Guidelines', path: '/policies#booking-guidelines' },
    ],
  },
];

export default index;
