import Link from 'next/link';
import Image from 'next/image';
import { useEffect, useState } from 'react';
import MainMenu from './MainMenu';
import MobileMenu from './MobileMenu';

const Header = ({
  tolerance = 10,
  additionalItems = [],
  className = () => 'bg-white is-sticky',
  menuTextStyle = () => 'text-dark-1',
  menuBgStyle = () => 'bg-dark-1 -blue-1',
  logo = () => 'black',
}) => {
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= tolerance) {
        setIsScrolledDown(true);
      } else {
        setIsScrolledDown(false);
      }
    };

    window.addEventListener('scroll', changeBackground);
  }, [tolerance]);

  return (
    <header className={`header no-print ${className(isScrolledDown)}`}>
      <div className='header__container px-30 sm:px-20'>
        <div className='row justify-between items-center'>
          <div className='col-auto d-flex items-center'>
            <Link href='/' className=''>
              <Image
                className='header-logo-lg'
                src={`/img/vclogo_text_${logo(isScrolledDown)}.png`}
                width={280}
                height={100}
                alt='victoria court logo'
                style={{ width: 280, height: 'auto' }}
              />
              <Image
                className='header-logo-sm'
                src='/img/VClogo.png'
                width={45}
                height={45}
                alt='victoria court logo'
                style={{ width: 45, height: 'auto' }}
              />
            </Link>
          </div>

          <div className='col-auto'>
            <div className='header-menu ml-20'>
              <div className='header-menu__content'>
                <MainMenu
                  additionalItems={additionalItems}
                  style={menuTextStyle(isScrolledDown)}
                  buttonStyle={menuBgStyle(isScrolledDown)}
                />
              </div>
            </div>
            <div className='d-none xl:d-flex x-gap-20 items-center pl-30 text-white'>
              <button
                className={`d-flex items-center icon-menu text-20 ${menuTextStyle(
                  isScrolledDown
                )}`}
                data-bs-toggle='offcanvas'
                aria-controls='mobile-sidebar_menu'
                data-bs-target='#mobile-sidebar_menu'
              />

              <div
                className='offcanvas offcanvas-start mobile_menu-contnet'
                tabIndex='-1'
                id='mobile-sidebar_menu'
                aria-labelledby='offcanvasMenuLabel'
                data-bs-scroll='true'
              >
                <MobileMenu
                  additionalItems={additionalItems}
                  buttonStyle={menuBgStyle(isScrolledDown)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
