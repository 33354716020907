import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import SEO from './common/Seo';
import Header from './Header';
import Footer from './Footer';

function ClientError() {
  return (
    <>
      <SEO pageTitle='Error' />

      <div className='header-margin'></div>

      <Header additionalItems={['book_now']} />

      <section className='layout-pt-md layout-pb-lg'>
        <div className='container'>
          <div className='row y-gap-30 justify-between items-center'>
            <div className='col-lg-6'>
              <Image
                src='/img/general/404.svg'
                height={300}
                width={800}
                alt='error image'
              />
            </div>
            <div className='col-lg-5'>
              <div className='no-page lg:text-center'>
                <h2 className='text-30 fw-600'>
                  Oops! An error occured in the client
                </h2>
                <div className='pr-30 mt-5'>
                  Try to refresh the page or go back to homepage
                </div>
                <div className='d-inline-block mt-40 md:mt-20'>
                  <Link
                    href='/'
                    className='button -md -dark-1 bg-blue-1 text-white'
                  >
                    Go back to homepage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  // runs after render
  // so basically reset state once component is shown
  componentDidUpdate(prevProps, prevState) {
    if (prevState.hasError && this.state.hasError) {
      this.setState({ hasError: false });
    }
  }

  componentDidCatch(error, errorInfo) {
    fetch('/api/log', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        message: error.message,
        path: window?.location?.pathname || '',
        stack: error.stack,
        componentStack: errorInfo.componentStack,
      }),
    });

    console.error(error);
    console.info(errorInfo);
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      return <ClientError />;
    }

    // Return children components in case of no error
    return this.props.children;
  }
}

export default ErrorBoundary;
