import Head from 'next/head';

const SEO = ({ pageTitle, pageDescription }) => (
  <Head>
    <title>
      {pageTitle ? `${pageTitle} | Victoria Court` : 'Victoria Court'}
    </title>
    <meta name='viewport' content='width=device-width, initial-scale=1' />
    {pageDescription && <meta name='description' content={pageDescription} />}
  </Head>
);

export default SEO;
