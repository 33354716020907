import socials from 'data/socials';

const Socials = ({ wrapper: Wrapper = DefaultWrapper }) => {
  return (
    <>
      {socials.map((item, i) => (
        <Wrapper key={i}>
          {item.svg ? (
            <a
              title={item.title}
              href={item.link}
              target='_blank'
              rel='noopener noreferrer'
              key={i}
              dangerouslySetInnerHTML={{ __html: item.svg }}
            ></a>
          ) : (
            <a
              title={item.title}
              href={item.link}
              target='_blank'
              rel='noopener noreferrer'
              key={i}
            >
              <i className={`${item.icon} text-20`} />
            </a>
          )}
        </Wrapper>
      ))}
    </>
  );
};

const DefaultWrapper = ({ children }) => <>{children}</>;

export default Socials;
