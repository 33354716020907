import addHours from 'date-fns/addHours';
import getHours from 'date-fns/getHours';

const ContactInfo = ({ classNames = {} }) => {
  const activePhoneNo = getActivePhoneNo();

  return (
    <div className={classNames.mainContainer}>
      <div className={classNames.container}>
        <p className={classNames.title}>Call us</p>
        <a href={`tel:${activePhoneNo}`} className={classNames.body}>
          {activePhoneNo}
        </a>
      </div>
      <div className={classNames.container}>
        <p className={`text-16 ${classNames.title}`}>Email us</p>

        <div className={classNames.container}>
          <p className={classNames.subtitle}>For booking and reservations</p>
          <a
            href={`mailto:${activeEmails.reservation}`}
            className={classNames.body}
          >
            {activeEmails.reservation}
          </a>
        </div>
        <div className={classNames.container}>
          <p className={classNames.subtitle}>
            For partnerships, inquiries, concerns, and complaints
          </p>
          <a
            href={`mailto:${activeEmails.inquiries}`}
            className={classNames.body}
          >
            {activeEmails.inquiries}
          </a>
        </div>
        <div className={classNames.container}>
          <p className={classNames.subtitle}>For job applications</p>
          <a
            href={`mailto:${activeEmails.careers}`}
            className={classNames.body}
          >
            {activeEmails.careers}
          </a>
        </div>
        <div className={classNames.container}>
          <p className={classNames.subtitle}>For supplier proposals</p>
          <a
            href={`mailto:${activeEmails.procurement}`}
            className={classNames.body}
          >
            {activeEmails.procurement}
          </a>
        </div>
      </div>
    </div>
  );
};

const getActivePhoneNo = () => {
  const currentHour = getHours(new Date());

  // Between 7AM and 3PM
  if (currentHour >= 7 && currentHour < 15) {
    return '(+63)917 827 3783';
  }

  // Between 3PM and 11PM
  if (currentHour >= 15 && currentHour < 23) {
    return '(+63)917 634 2637';
  }

  // Between 11PM and 7AM
  if (currentHour >= 23 || currentHour < 7) {
    return '(+63)917 708 0883';
  }

  return '(+63)917-VCSERVE';
};

const activeEmails = {
  reservation: 'reservation@victoriacourt.com',
  inquiries: 'serve@victoriacourt.com',
  careers: 'careers@victoriacourt.com',
  procurement: 'quotations@victoriacourt.com',
};

export default ContactInfo;
