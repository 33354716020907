import Link from 'next/link';
import { useRouter } from 'next/router';
import Socials from 'components/common/Socials';

const MainMenu = ({ additionalItems, style = '', buttonStyle = '' }) => {
  const router = useRouter();

  return (
    <nav className='menu d-flex x-gap-10 items-center js-navList h-25'>
      <ul className={`menu__nav ${style} -is-active`}>
        {additionalItems.includes('rooms') && (
          <li className={router.pathname === '/rooms' ? 'current' : ''}>
            <Link href='/rooms'>Rooms</Link>
          </li>
        )}
        <li className={router.pathname === '/about' ? 'current' : ''}>
          <Link href='/about'>About</Link>
        </li>

        <li className={router.pathname === '/help' ? 'current' : ''}>
          <Link href='/help'>Help</Link>
        </li>
      </ul>

      <div className={`text-22 ${style}`} style={{ opacity: 0.4 }}>
        |
      </div>

      <ul className={`menu__nav ${style} -is-active`}>
        <li>
          <Link href='https://www.victoriabites.com'>Victoria Bites</Link>
        </li>

        <li>
          <Link href='https://webstore.paynamics.net/victoriacourtvc'>
            Victoria Style
          </Link>
        </li>
      </ul>

      <div className={`text-22 ${style}`} style={{ opacity: 0.4 }}>
        |
      </div>

      <ul className={`menu__nav mr-20 ${style} -is-active`}>
        <Socials wrapper={({ children }) => <li>{children}</li>} />
      </ul>

      {additionalItems.includes('book_now') && (
        <>
          <div className='btn-group'>
            <button
              type='button'
              className={`button btn dropdown-toggle h-50 px-24 text-white ${buttonStyle}`}
              data-bs-toggle='dropdown'
              aria-expanded='false'
            >
              Book Now
            </button>
            <ul className='dropdown-menu dropdown-menu-end'>
              <li>
                <Link
                  className='dropdown-item text-white'
                  href={{ pathname: '/rooms', query: { isparty: false } }}
                  as='/rooms'
                >
                  Couple Room
                </Link>
              </li>
              <li>
                <hr className='dropdown-divider' />
              </li>
              <li>
                <Link
                  className='dropdown-item text-white'
                  href={{
                    pathname: '/rooms',
                    query: { isparty: true },
                  }}
                  as='/rooms'
                >
                  Party Room
                </Link>
              </li>
            </ul>
          </div>
        </>
      )}
    </nav>
  );
};

export default MainMenu;
